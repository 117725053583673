import { useTranslation } from '@pancakeswap/localization'
import { AutoColumn, Button, Text } from '@pancakeswap/uikit'
import replaceBrowserHistory from '@pancakeswap/utils/replaceBrowserHistory'
import { useExpertMode } from '@pancakeswap/utils/user'
import { Swap as SwapUI } from '@pancakeswap/widgets-internal'
import { AutoRow } from 'components/Layout/Row'
import { memo, useCallback, useMemo } from 'react'
import { Field } from 'state/swap/actions'
import { useSwapState } from 'state/swap/hooks'
import { useSwapActionHandlers } from 'state/swap/useSwapActionHandlers'
import styled, { css } from 'styled-components'
import { useAllowRecipient } from '../hooks'

const Position = styled.div<{ $isExpertMode?: boolean }>`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  z-index: 1;
  ${({ $isExpertMode }) =>
    $isExpertMode &&
    css`
      top: 50%;
      right: 0;
      transform: translate(25%, -50%);
    `}
`

export const FlipButton = memo(function FlipButton() {
  const { t } = useTranslation()
  const [isExpertMode] = useExpertMode()
  const { onSwitchTokens, onChangeRecipient } = useSwapActionHandlers()
  const {
    recipient,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState()
  const allowRecipient = useAllowRecipient()

  const onFlip = useCallback(() => {
    onSwitchTokens()
    replaceBrowserHistory('inputCurrency', outputCurrencyId)
    replaceBrowserHistory('outputCurrency', inputCurrencyId)
  }, [onSwitchTokens, inputCurrencyId, outputCurrencyId])

  const isAllowRecipient = useMemo(() => allowRecipient && recipient === null, [allowRecipient, recipient])

  return (
    <>
      <Position $isExpertMode={isExpertMode && isAllowRecipient}>
        <AutoColumn justify="space-between" position="relative">
          <AutoRow justify={isExpertMode ? 'space-between' : 'center'} style={{ padding: '0', margin: '1em 0' }}>
            <SwapUI.SwitchButton onClick={onFlip} />
          </AutoRow>
        </AutoColumn>
      </Position>
      {isAllowRecipient ? (
        <Button variant="text" width="100%" px="0" id="add-recipient-button" onClick={() => onChangeRecipient('')}>
          <Text bold textAlign="left" width="100%">
            {t('+ Add a send (optional)')}
          </Text>
        </Button>
      ) : null}
    </>
  )
})
