import { useTranslation } from '@pancakeswap/localization'
import { Currency, Price } from '@pancakeswap/sdk'
import { Box, ChevronDownIcon, Flex, useModal } from '@pancakeswap/uikit'
import { useUserSlippage } from '@pancakeswap/utils/user'
import { Swap as SwapUI } from '@pancakeswap/widgets-internal'
import { memo, useState } from 'react'
import SettingsModal from '../../../../components/Menu/GlobalSettings/SettingsModal'
import { SettingsMode } from '../../../../components/Menu/GlobalSettings/types'
import { useIsWrapping } from '../hooks'

interface Props {
  showSlippage?: boolean
  priceLoading?: boolean
  price?: Price<Currency, Currency>
  tradeDetails?: React.ReactNode
}

export const PricingAndSlippage = memo(function PricingAndSlippage({
  priceLoading,
  price,
  showSlippage = false,
  tradeDetails,
}: Props) {
  const { t } = useTranslation()
  const [allowedSlippage] = useUserSlippage()
  const isWrapping = useIsWrapping()
  const [onPresentSettingsModal] = useModal(<SettingsModal mode={SettingsMode.SWAP_LIQUIDITY} />)
  const [openDetails, setOpenDetails] = useState(false)

  if (isWrapping) {
    return null
  }

  const onOpen = () => setOpenDetails((prev) => !prev)

  const priceNode = price ? (
    <>
      <Box width="100%" borderRadius="card" backgroundColor="tertiary" overflow="hidden" px="12px">
        <Flex justifyContent="space-between" minHeight={45} width="100%" zIndex="1" onClick={onOpen}>
          {/* <SwapUI.InfoLabel>{t('Price')}</SwapUI.InfoLabel> */}
          <SwapUI.TradePrice price={price} loading={priceLoading} />
          <ChevronDownIcon />
        </Flex>
        {openDetails ? tradeDetails : null}
      </Box>
    </>
  ) : null

  return (
    <>
      <SwapUI.Info
        price={priceNode}
        allowedSlippage={showSlippage ? allowedSlippage : undefined}
        onSlippageClick={onPresentSettingsModal}
      />
    </>
  )
})
