import { SUPPORT_SWAP } from 'config/constants/supportChains'
import Swap from 'views/Swap'

const SwapPage = () => {
  return <Swap />
}

SwapPage.chains = SUPPORT_SWAP

export default SwapPage
