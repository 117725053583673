import { useCurrency } from 'hooks/Tokens'
import { useMemo } from 'react'
import { Field } from 'state/swap/actions'
import { useDerivedSwapInfo, useSwapState } from 'state/swap/hooks'
import { FormHeader, FormMain, PricingAndSlippage, SwapCommitButton, TradeDetails } from './containers'

function V2SwapForm() {
  const {
    independentField,
    typedValue,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
    recipient,
  } = useSwapState()

  const inputCurrency = useCurrency(inputCurrencyId)
  const outputCurrency = useCurrency(outputCurrencyId)

  const { v2Trade: trade } = useDerivedSwapInfo(independentField, typedValue, inputCurrency, outputCurrency, recipient)

  const price = useMemo(() => trade && trade.executionPrice, [trade])

  return (
    <>
      <FormHeader onRefresh={() => {}} refreshDisabled />
      <FormMain
        tradeLoading={false}
        pricingAndSlippage={
          <PricingAndSlippage priceLoading={false} price={price} tradeDetails={<TradeDetails trade={trade} loaded />} />
        }
        inputAmount={trade?.inputAmount}
        outputAmount={trade?.outputAmount}
        swapCommitButton={<SwapCommitButton trade={trade} tradeError={null} tradeLoading={false} />}
      />

      {/* <TradeDetails trade={trade} loaded /> */}
    </>
  )
}
export default V2SwapForm
