import { Currency, Trade, TradeType } from '@pancakeswap/sdk'
import { AutoColumn } from '@pancakeswap/uikit'
import { memo, useMemo } from 'react'

import { TradeSummary } from 'views/Swap/components/AdvancedSwapDetails'
import { AdvancedDetailsFooter } from 'views/Swap/components/AdvancedSwapDetailsDropdown'

import { computeTradePriceBreakdown } from 'utils/exchange'
import { useIsWrapping, useSlippageAdjustedAmounts } from '../hooks'

interface Props {
  loaded: boolean
  trade?: Trade<Currency, Currency, TradeType> | null
}

export const TradeDetails = memo(function TradeDetails({ loaded, trade }: Props) {
  const slippageAdjustedAmounts = useSlippageAdjustedAmounts(trade)
  const isWrapping = useIsWrapping()
  const { priceImpactWithoutFee, realizedLPFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  // const hasStablePool = useMemo(() => trade?.route.some((route) => route.pools.some(SmartRouter.isStablePool)), [trade])

  if (isWrapping || !loaded || !trade) {
    return null
  }

  const { inputAmount, outputAmount, tradeType, route } = trade

  return (
    <AdvancedDetailsFooter show={loaded}>
      <AutoColumn gap="0px">
        <TradeSummary
          slippageAdjustedAmounts={slippageAdjustedAmounts}
          inputAmount={inputAmount}
          outputAmount={outputAmount}
          tradeType={tradeType}
          priceImpactWithoutFee={priceImpactWithoutFee}
          realizedLPFee={realizedLPFee}
          hasStablePair={false}
        />
        {/* <RoutesBreakdown routes={route} /> */}
      </AutoColumn>
    </AdvancedDetailsFooter>
  )
})
