import { useTranslation } from '@pancakeswap/localization'
import { NATIVE } from '@pancakeswap/sdk'
import {
  ArrowDownIcon,
  ArrowUpIcon,
  Box,
  Button,
  CheckmarkCircleIcon,
  Flex,
  InfoIcon,
  // UserMenu,
  NetworkSelectBox,
  Text,
  UserMenuDivider,
  UserMenuItem,
  useTooltip,
} from '@pancakeswap/uikit'
import { ChainId, chains, chainsSetup } from '@sdk/constants'
import { useActiveChainId, useLocalNetworkChain } from 'hooks/useActiveChainId'
import { useNetworkConnectorUpdater } from 'hooks/useActiveWeb3React'
import { useHover } from 'hooks/useHover'
import { useSessionChainId } from 'hooks/useSessionChainId'
import { useSwitchNetwork } from 'hooks/useSwitchNetwork'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { useAccount } from 'wagmi'

import { MODE_DEV } from 'config/constants'
import styled from 'styled-components'
import { chainNameConverter } from 'utils/chainNameConverter'
import { ChainLogo, getChainLogo } from './Logo/ChainLogo'

const StyledWrapList = styled(Box)`
  overflow-y: scroll;
  max-height: 80vh;
  width: 100%;

  &::-webkit-scrollbar {
    width: 1px;
  }
  &::-webkit-scrollbar-thumb {
    box-shadow: unset;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    box-shadow: unset;
  }
`

const StyledUserMenuItem = styled(UserMenuItem)`
  min-height: 40px;

  &:hover:not(:disabled) {
    & ${Text} {
      color: white;
    }
  }
`

const NetworkSelect = ({ switchNetwork, chainId }) => {
  const { t } = useTranslation()

  const renderContent = useMemo(() => {
    return chains
      .filter((chain) => {
        if (chain.id === chainId) return true
        if ('testnet' in chain && chain.testnet) {
          return MODE_DEV
        }
        return true
      })
      .map((chain) => (
        <StyledUserMenuItem
          key={`network-select-switcher#${chain.id}`}
          style={{ justifyContent: 'space-between' }}
          onClick={() => chain.id !== chainId && switchNetwork(chain.id)}
        >
          <Flex alignItems="center">
            <ChainLogo chainId={chain.id} />
            <Text
              whiteSpace="nowrap"
              color={chain.id === chainId ? 'primary' : 'var(--black-color)'}
              bold={chain.id === chainId}
              pl="12px"
            >
              {chainNameConverter(chain.name)}
            </Text>
          </Flex>
          {chain.id === chainId ? <CheckmarkCircleIcon color="rgba(224, 164, 42, 1)" /> : null}
        </StyledUserMenuItem>
      ))
  }, [chainId, switchNetwork])

  return (
    <>
      <Box px="16px">
        <Text color="var(--black-color)" py="8px">
          {t('Select Chain')}
        </Text>
        <UserMenuDivider $color="var(--black-color)" />
      </Box>
      {renderContent}
    </>
  )
}

const WrongNetworkSelect = ({ switchNetwork, chainId }) => {
  const { t } = useTranslation()
  const { targetRef, tooltip, tooltipVisible } = useTooltip(
    t(
      'The URL you are accessing (Chain id: %chainId%) belongs to %network%; mismatching your wallet’s network. Please switch the network to continue.',
      {
        chainId,
        network: chains.find((c) => c.id === chainId)?.name ?? 'Unknown network',
      },
    ),
    {
      placement: 'auto-start',
      hideTimeout: 0,
    },
  )
  const { chain } = useAccount()
  const localChainId = useLocalNetworkChain() || ChainId.ZULU_TESTNET
  const [, setSessionChainId] = useSessionChainId()

  const localChainName = chains.find((c) => c.id === localChainId)?.name ?? 'BSC'

  const [ref1, isHover] = useHover<HTMLButtonElement>()

  return (
    <>
      <Flex ref={targetRef} alignItems="center" px="12px" py="8px">
        <InfoIcon color="failure" width="24px" />
        <Text color="black" pl="6px">
          {t('Please switch network')}
        </Text>
      </Flex>
      {tooltipVisible && tooltip}
      <UserMenuDivider />
      {chain ? (
        <StyledUserMenuItem
          ref={ref1}
          onClick={() => setSessionChainId(chain.id)}
          style={{ justifyContent: 'flex-start' }}
        >
          <ChainLogo chainId={chain.id} />
          <Text color="primary" bold pl="12px">
            {chainNameConverter(chain.name)}
          </Text>
        </StyledUserMenuItem>
      ) : (
        chainId && (
          <StyledUserMenuItem
            ref={ref1}
            onClick={() => setSessionChainId(chainId)}
            style={{ justifyContent: 'flex-start' }}
          >
            <ChainLogo chainId={chainId} />
            <Text color="primary" bold pl="12px">
              Chain {chainId}
            </Text>
          </StyledUserMenuItem>
        )
      )}
      <Box px="16px" pt="8px">
        {isHover ? <ArrowUpIcon color="black" /> : <ArrowDownIcon color="black" />}
      </Box>
      <StyledUserMenuItem onClick={() => switchNetwork(localChainId)} style={{ justifyContent: 'flex-start' }}>
        <ChainLogo chainId={localChainId} />
        <Text color="black" pl="12px">
          {localChainName}
        </Text>
      </StyledUserMenuItem>
      <Box px="12px">
        <Button width="100%" mx="0" my="8px" scale="sm" onClick={() => switchNetwork(localChainId)}>
          {t('Switch network in wallet')}
        </Button>
      </Box>
    </>
  )
}

// ADD CHAIN
const SHORT_SYMBOL = {
  [ChainId.ZETA]: 'ZETA',
  [ChainId.ZETA_TESTNET]: 'ZETA',
  [ChainId.ZULU_TESTNET]: 'BTC',
} as const satisfies Record<ChainId, string>

export const NetworkSwitcher = () => {
  const { t } = useTranslation()
  const { chainId, walletChainId, isWrongNetwork, isNotMatched } = useActiveChainId()
  const { pendingChainId, isLoading, canSwitch, switchNetworkAsync } = useSwitchNetwork()
  const router = useRouter()
  useNetworkConnectorUpdater()

  const foundChain = useMemo(
    () => chainsSetup.find((c) => c.id === (isLoading ? pendingChainId || chainId : chainId)),
    [isLoading, pendingChainId, chainId],
  )

  const symbol =
    (foundChain?.id ? SHORT_SYMBOL[foundChain.id] ?? NATIVE[foundChain.id]?.symbol : undefined) ??
    foundChain?.nativeCurrency?.symbol

  const { targetRef, tooltip, tooltipVisible } = useTooltip(
    t('Unable to switch network. Please try it on your wallet'),
    { placement: 'bottom' },
  )

  const cannotChangeNetwork = !canSwitch

  if (!chainId || router.pathname.includes('/info')) {
    return null
  }

  return (
    <Box ref={cannotChangeNetwork ? targetRef : null} width="100%" height="100%">
      {cannotChangeNetwork && tooltipVisible && tooltip}
      <NetworkSelectBox
        maxHeight="80vh"
        placement="bottom"
        variant={isLoading ? 'pending' : isWrongNetwork ? 'danger' : 'default'}
        avatarSrc={getChainLogo(chainId)}
        disabled={cannotChangeNetwork}
        text={
          isLoading ? (
            t('Requesting')
          ) : isWrongNetwork ? (
            t('Network')
          ) : foundChain ? (
            <>
              <Box display={['none', null, null, null, null, 'block']}>{chainNameConverter(foundChain.name)}</Box>
              <Box display={['block', null, null, null, null, 'none']}>{symbol}</Box>
            </>
          ) : (
            <Text color="text80" bold pl="12px">
              {t('Select a Network')}
            </Text>
          )
        }
      >
        {() =>
          isNotMatched ? (
            <WrongNetworkSelect switchNetwork={switchNetworkAsync} chainId={walletChainId} />
          ) : (
            <StyledWrapList>
              <NetworkSelect switchNetwork={switchNetworkAsync} chainId={chainId} />
            </StyledWrapList>
          )
        }
      </NetworkSelectBox>
    </Box>
  )
}
