import { useTranslation } from '@pancakeswap/localization'
import { Currency, CurrencyAmount, Percent, TradeType } from '@pancakeswap/sdk'
import { AutoColumn, Button, ErrorIcon, Flex, Text } from '@pancakeswap/uikit'
import { formatAmount } from '@pancakeswap/utils/formatFractions'
import truncateHash from '@pancakeswap/utils/truncateHash'
import { chainsSetup } from '@sdk/constants'
import { RowBetween, RowFixed } from 'components/Layout/Row'
import { CurrencyLogo } from 'components/Logo'
import { ChainLogo } from 'components/Logo/ChainLogo'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { useSwitchNetwork } from 'hooks/useSwitchNetwork'
import { ReactElement, useMemo } from 'react'
import { Field } from 'state/swap/actions'
import { chainNameConverter } from 'utils/chainNameConverter'
import { warningSeverity } from 'utils/exchange'
import { SwapShowAcceptChanges, TruncatedText } from './styleds'

export default function SwapModalHeader({
  inputAmount,
  outputAmount,
  tradeType,
  currencyBalances,
  priceImpactWithoutFee,
  slippageAdjustedAmounts,
  isEnoughInputBalance,
  recipient,
  showAcceptChanges,
  onAcceptChanges,
  allowedSlippage,
}: {
  inputAmount: CurrencyAmount<Currency>
  outputAmount: CurrencyAmount<Currency>
  currencyBalances: {
    INPUT?: CurrencyAmount<Currency>
    OUTPUT?: CurrencyAmount<Currency>
  }
  tradeType: TradeType
  priceImpactWithoutFee?: Percent
  slippageAdjustedAmounts: { [field in Field]?: CurrencyAmount<Currency> }
  isEnoughInputBalance?: boolean
  recipient?: string
  showAcceptChanges: boolean
  onAcceptChanges: () => void
  allowedSlippage: number | ReactElement
}) {
  const { t } = useTranslation()

  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  const inputTextColor =
    showAcceptChanges && tradeType === TradeType.EXACT_OUTPUT && isEnoughInputBalance
      ? 'primary'
      : tradeType === TradeType.EXACT_OUTPUT && !isEnoughInputBalance
      ? 'failure'
      : 'white'

  const amount =
    tradeType === TradeType.EXACT_INPUT
      ? formatAmount(slippageAdjustedAmounts[Field.OUTPUT], 6)
      : formatAmount(slippageAdjustedAmounts[Field.INPUT], 6)
  const symbol = tradeType === TradeType.EXACT_INPUT ? outputAmount.currency.symbol : inputAmount.currency.symbol

  const tradeInfoText = useMemo(() => {
    return tradeType === TradeType.EXACT_INPUT
      ? t('Output is estimated. You will receive at least %amount% %symbol% or the transaction will revert.', {
          amount: `${amount}`,
          symbol,
        })
      : t('Input is estimated. You will sell at most %amount% %symbol% or the transaction will revert.', {
          amount: `${amount}`,
          symbol,
        })
  }, [t, tradeType, amount, symbol])

  const truncatedRecipient = recipient ? truncateHash(recipient) : ''

  const recipientInfoText = t('Output will be sent to %recipient%', {
    recipient: truncatedRecipient,
  })

  const [recipientSentToText, postSentToText] = recipientInfoText.split(truncatedRecipient)
  const { pendingChainId, isLoading } = useSwitchNetwork()
  const { chainId } = useActiveChainId()
  const foundChain = useMemo(
    () => chainsSetup.find((c) => c.id === (isLoading ? pendingChainId || chainId : chainId)),
    [isLoading, pendingChainId, chainId],
  )

  return (
    <>
      <Flex alignItems="center" mb="12px">
        <Flex mr="6px">
          <ChainLogo chainId={chainId} width={25} height={25} />
        </Flex>
        <Text>{chainNameConverter(foundChain.name)}</Text>
      </Flex>

      <AutoColumn gap="sm">
        <Flex flexDirection="column" backgroundColor="tertiary" padding="10px 12px" borderRadius="8px">
          <Text fontSize="12px">Sell</Text>
          <RowBetween align="flex-end" borderRadius="8px">
            <Flex flexDirection="column">
              <RowFixed gap="4px">
                <TruncatedText maxWidth="245px" fontSize="28px" bold color={inputTextColor}>
                  {formatAmount(inputAmount, 6)}
                </TruncatedText>
                <Text fontSize="28px" bold ml="10px" mr="8px">
                  {inputAmount.currency.symbol}
                </Text>
              </RowFixed>
            </Flex>
            <RowFixed style={{ alignSelf: 'center' }}>
              <CurrencyLogo currency={currencyBalances.INPUT?.currency ?? inputAmount.currency} size="24px" />
            </RowFixed>
          </RowBetween>
        </Flex>
        <Flex flexDirection="column" backgroundColor="tertiary" padding="10px 12px" borderRadius="8px">
          <Text fontSize="12px">Buy</Text>
          <RowBetween align="flex-end" borderRadius="8px">
            <Flex flexDirection="column">
              <RowFixed gap="4px">
                <TruncatedText
                  maxWidth="245px"
                  fontSize="28px"
                  bold
                  color={
                    priceImpactSeverity > 2
                      ? 'failure'
                      : showAcceptChanges && tradeType === TradeType.EXACT_INPUT
                      ? 'primary'
                      : 'white'
                  }
                >
                  {formatAmount(outputAmount, 6)}
                </TruncatedText>
                <Text fontSize="28px" bold ml="10px" mr="8px" color="white">
                  {outputAmount.currency.symbol}
                </Text>
              </RowFixed>
            </Flex>
            <RowFixed style={{ alignSelf: 'center' }}>
              <CurrencyLogo currency={currencyBalances.OUTPUT?.currency ?? outputAmount.currency} size="24px" />
            </RowFixed>
          </RowBetween>
        </Flex>
        {showAcceptChanges ? (
          <SwapShowAcceptChanges justify="flex-start" gap="0px">
            <RowBetween>
              <RowFixed>
                <ErrorIcon color="primary" mr="8px" />
                <Text color="primary" bold>
                  {' '}
                  {t('Price Updated')}
                </Text>
              </RowFixed>
              <Button onClick={onAcceptChanges}>{t('Accept')}</Button>
            </RowBetween>
          </SwapShowAcceptChanges>
        ) : null}
        {/* <AutoColumn justify="flex-start" gap="sm" style={{ padding: '24px 0 0 0px' }}>
          <RowFixed style={{ width: '100%' }}>
            <Text fontSize={12} color="primary" bold textTransform="uppercase">
              {t('Slippage Tolerance')}
            </Text>
            <Text fontSize={12} bold color="primary" ml="auto" textAlign="end">
              {typeof allowedSlippage === 'number'
                ? `${basisPointsToPercent(allowedSlippage).toFixed(2)}%`
                : allowedSlippage}
            </Text>
          </RowFixed>
          {tradeType === TradeType.EXACT_OUTPUT && !isEnoughInputBalance && (
            <Text fontSize={12} color="failure" textAlign="left" style={{ width: '100%' }}>
              {t('Insufficient input token balance. Your transaction may fail.')}
            </Text>
          )}
          <Text fontSize={12} color="black" textAlign="left" fontStyle="italic">
            {tradeInfoText}
          </Text>
        </AutoColumn>
        {recipient ? (
          <AutoColumn justify="flex-start" gap="sm" style={{ padding: '12px 0 0 0px' }}>
            <Text fontSize={12} color="black">
              {recipientSentToText}
              <b title={recipient}>{truncatedRecipient}</b>
              {postSentToText}
            </Text>
          </AutoColumn>
        ) : null} */}
      </AutoColumn>
    </>
  )
}
