import { styled } from 'styled-components'

export const AdvancedDetailsFooter = styled.div<{ show: boolean }>`
  /* margin-top: ${({ show }) => (show ? '16px' : 0)}; */
  /* padding-top: 16px; */
  /* padding-bottom: 16px; */
  width: 100%;
  background-color: ${({ theme }) => theme.colors.tertiary};
  border-top: 1px solid var(--line);

  transform: ${({ show }) => (show ? 'translateY(0%)' : 'translateY(-100%)')};
  transition: transform 300ms ease-in-out;
`
