import { Flex } from '@pancakeswap/uikit'
import { AppBody } from 'components/App'

import { useDefaultsFromURLSearch } from 'state/swap/hooks'
import Page from '../Page'
import V2SwapForm from './V2Swap'
import { StyledSwapContainer } from './styles'

const Swap = () => {
  useDefaultsFromURLSearch()

  return (
    <Page removePadding={false} hideFooterOnDesktop={false}>
      <Flex width={['328px', '100%']} justifyContent="center" position="relative" alignItems="center">
        <Flex flexDirection="column" flex={1} justifyContent="center" alignItems="center">
          <StyledSwapContainer>
            <AppBody>
              <V2SwapForm />
            </AppBody>
          </StyledSwapContainer>
        </Flex>
      </Flex>
    </Page>
  )
}
export default Swap
