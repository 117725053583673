import { useTranslation } from '@pancakeswap/localization'
import { Currency, CurrencyAmount, Percent, Trade, TradeType } from '@pancakeswap/sdk'
import {
  AutoColumn,
  Box,
  // BackForwardIcon,
  // Link,
  // QuestionHelper,
  Button,
  ChevronDownIcon,
  Dots,
  Flex,
  Text,
} from '@pancakeswap/uikit'
// import { formatAmount } from '@pancakeswap/utils/formatFractions'
import { AutoRow } from 'components/Layout/Row'
// import { CurrencyLogo } from 'components/Logo'
import { BUYBACK_FEE, LP_HOLDERS_FEE, TOTAL_FEE, TREASURY_FEE } from 'config/constants/info'
import { memo, useMemo, useState } from 'react'
import { Field } from 'state/swap/actions'
import { styled } from 'styled-components'
import { formatExecutionPrice, warningSeverity } from 'utils/exchange'
import { TradeDetails } from 'views/Swap/V2Swap/containers'
// import FormattedPriceImpact from '../../components/FormattedPriceImpact'
import { Swap as SwapUI } from '@pancakeswap/widgets-internal'
import {
  // StyledBalanceMaxMini,
  SwapCallbackError,
} from '../../components/styleds'

const SwapModalFooterContainer = styled(AutoColumn)`
  margin-top: 24px;
  padding: 16px;
  border-radius: ${({ theme }) => theme.radii.default};
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  background-color: ${({ theme }) => theme.colors.background};
  ${Text} {
    color: white;
  }
  svg {
    fill: white;
  }
`

export const SwapModalFooter = memo(function SwapModalFooter({
  priceImpact: priceImpactWithoutFee,
  lpFee: realizedLPFee,
  inputAmount,
  outputAmount,
  trade,
  slippageAdjustedAmounts,
  isEnoughInputBalance,
  onConfirm,
  swapErrorMessage,
  disabledConfirm,
  isMM,
  isRFQReady,
  currencyBalances,
}: {
  trade: Trade<Currency, Currency, TradeType>
  lpFee?: CurrencyAmount<Currency>
  inputAmount: CurrencyAmount<Currency>
  outputAmount: CurrencyAmount<Currency>
  priceImpact?: Percent
  slippageAdjustedAmounts: { [field in Field]?: CurrencyAmount<Currency> }
  isEnoughInputBalance?: boolean
  swapErrorMessage?: string | undefined
  disabledConfirm: boolean
  isMM?: boolean
  isRFQReady?: boolean
  currencyBalances: {
    INPUT?: CurrencyAmount<Currency>
    OUTPUT?: CurrencyAmount<Currency>
  }
  onConfirm: () => void
}) {
  const { t } = useTranslation()
  const [showInverted, setShowInverted] = useState<boolean>(false)
  const severity = warningSeverity(priceImpactWithoutFee)

  const totalFeePercent = `${(TOTAL_FEE * 100).toFixed(2)}%`
  const lpHoldersFeePercent = `${(LP_HOLDERS_FEE * 100).toFixed(2)}%`
  const treasuryFeePercent = `${(TREASURY_FEE * 100).toFixed(4)}%`
  const buyBackFeePercent = `${(BUYBACK_FEE * 100).toFixed(4)}%`

  const executionPriceDisplay = useMemo(() => {
    return formatExecutionPrice(trade, showInverted)
  }, [trade, showInverted])

  const price = useMemo(() => trade && trade.executionPrice, [trade])

  const [openDetails, setOpenDetails] = useState(false)

  const onOpen = () => setOpenDetails((prev) => !prev)

  return (
    <>
      <Box width="100%" borderRadius="card" backgroundColor="tertiary" overflow="hidden" mt="8px" px="12px">
        <Flex width="100%" justifyContent="space-between" py="16px" onClick={onOpen}>
          <SwapUI.TradePrice price={price} loading={false} />
          <ChevronDownIcon />
        </Flex>

        {openDetails ? <TradeDetails trade={trade} loaded /> : null}
      </Box>
      {/* <SwapModalFooterContainer>
        <RowBetween align="center" mb="8px">
          <Text small>{t('Price')}</Text>
          <Text
            small
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              textAlign: 'right',
              paddingLeft: '10px',
            }}
          >
            {executionPriceDisplay}
            <StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
              <BackForwardIcon color="primary" width="14px" />
            </StyledBalanceMaxMini>
          </Text>
        </RowBetween>

        <RowBetween mb="8px">
          <RowFixed>
            <Text small>{trade?.tradeType === TradeType.EXACT_INPUT ? t('Minimum received') : t('Maximum sold')}</Text>
            <QuestionHelper
              text={t(
                'Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.',
              )}
              ml="4px"
              placement="top"
            />
          </RowFixed>
          <RowFixed>
            <Text small>
              {trade?.tradeType === TradeType.EXACT_INPUT
                ? formatAmount(slippageAdjustedAmounts[Field.OUTPUT], 4) ?? '-'
                : formatAmount(slippageAdjustedAmounts[Field.INPUT], 4) ?? '-'}
            </Text>
            <Text small marginLeft="4px">
              {trade?.tradeType === TradeType.EXACT_INPUT ? outputAmount.currency.symbol : inputAmount.currency.symbol}
            </Text>
          </RowFixed>
        </RowBetween>

        <RowBetween mb="8px">
          <RowFixed>
            <Text small>{t('Price Impact')}</Text>
            <QuestionHelper
              ml="4px"
              placement="top"
              text={
                isMM ? (
                  <>
                    <Text>
                      <Text bold display="inline-block">
                        {t('AMM')}
                      </Text>
                      {`: ${t('The difference between the market price and estimated price due to trade size.')}`}
                    </Text>
                    <Text mt="10px">
                      <Text bold display="inline-block">
                        {t('MM')}
                      </Text>
                      {`: ${t('No slippage against quote from market maker')}`}
                    </Text>
                  </>
                ) : (
                  <>{t('The difference between the market price and your price due to trade size.')}</>
                )
              }
            />
          </RowFixed>
          {isMM ? <Text color="textSubtle">--</Text> : <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />}
        </RowBetween>

        <RowBetween>
          <RowFixed>
            <Text small>{t('Trading Fee')}</Text>
            <QuestionHelper
              ml="4px"
              placement="top"
              text={
                isMM ? (
                  <>
                    <Text mb="12px">
                      <Text bold display="inline-block">
                        {t('AMM')}
                      </Text>
                      : {t('For each non-stableswap trade, a %amount% fee is paid', { amount: totalFeePercent })}
                    </Text>
                    <Text>- {t('%amount% to LP token holders', { amount: lpHoldersFeePercent })}</Text>
                    <Text>- {t('%amount% to the Treasury', { amount: treasuryFeePercent })}</Text>
                    <Text>- {t('%amount% towards CAKE buyback and burn', { amount: buyBackFeePercent })}</Text>
                    <Text mt="12px">
                      {t('For each stableswap trade, refer to the fee table')}
                      <Link
                        style={{ display: 'inline' }}
                        ml="4px"
                        external
                        href="https://docs.pancakeswap.finance/products/stableswap#stableswap-fees"
                      >
                        {t('here.')}
                      </Link>
                    </Text>
                    <Text mt="10px">
                      <Text bold display="inline-block">
                        {t('MM')}
                      </Text>
                      :{' '}
                      {t(
                        'PancakeSwap does not charge any fees for trades. However, the market makers charge an implied fee of 0.05% - 0.25% (non-stablecoin) / 0.01% (stablecoin) factored into the quotes provided by them.',
                      )}
                    </Text>
                  </>
                ) : (
                  <>
                    <Text color="white">
                      {t(
                        'Fee ranging from 0.1% to 0.01% depending on the pool fee tier. You can check the fee tier by clicking the magnifier icon under the “Route” section.',
                      )}
                    </Text>
                    <Text mt="12px">
                      <Link
                        style={{ display: 'inline' }}
                        ml="4px"
                        external
                        href="https://docs.pancakeswap.finance/products/pancakeswap-exchange/faq#what-will-be-the-trading-fee-breakdown-for-v3-exchange"
                      >
                        {t('Fee Breakdown and Tokenomics')}
                      </Link>
                    </Text>
                  </>
                )
              }
            />
          </RowFixed>
          {realizedLPFee ? (
            <Flex>
              <Text small mr="8px">
                {`${formatAmount(realizedLPFee, 6)} ${inputAmount.currency.symbol}`}
              </Text>
              <CurrencyLogo currency={currencyBalances.INPUT?.currency ?? inputAmount.currency} size="24px" />
            </Flex>
          ) : (
            <Text small textAlign="right">
              -
            </Text>
          )}
        </RowBetween>
      </SwapModalFooterContainer> */}

      <AutoRow>
        <Button
          variant={severity > 2 ? 'danger' : 'primary'}
          onClick={onConfirm}
          disabled={isMM ? disabledConfirm || !isRFQReady : disabledConfirm}
          mt="12px"
          id="confirm-swap-or-send"
          width="100%"
        >
          {isMM && !isRFQReady ? (
            <Dots>{t('Checking RFQ with MM')}</Dots>
          ) : severity > 2 || (trade?.tradeType === TradeType.EXACT_OUTPUT && !isEnoughInputBalance) ? (
            t('Swap Anyway')
          ) : (
            t('Swap')
          )}
        </Button>

        {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
      </AutoRow>
    </>
  )
})
